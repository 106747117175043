import { EntityState, EntityStore, getEntityType } from "@datorama/akita";
import { DocumentReference } from "@angular/fire/firestore";

export class FirebaseStore<S extends EntityState = any, EntityType = getEntityType<S>> extends EntityStore<S> {
  akitaPreAddEntity(entity: any): getEntityType<S> {
    FirebaseStore.removeUndefined(entity);
    return entity;
  }

  akitaPreUpdateEntity(_: Readonly<EntityType>, nextEntity: any): getEntityType<S> {
    FirebaseStore.removeUndefined(nextEntity);
    return nextEntity;
  }

  toId(obj: string | DocumentReference) {
    if (!obj) return null;
    return typeof obj === "string" ? obj : (<DocumentReference>obj).id;
  }

  static removeUndefined<T>(obj: T): T {
    if (Array.isArray(obj)) {
      obj.forEach(o => this.removeUndefined(o));
    } else {
      Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key]);
    }

    return obj;
  }
}
