import { Pipe, PipeTransform } from "@angular/core";
import { Timestamp } from "@firebase/firestore-types";

@Pipe({
  name: "timestamp"
})
export class TimestampPipe implements PipeTransform {
  transform(maybeTimestamp: Date | Timestamp): Date {
    return TimestampPipe.asDate(maybeTimestamp);
  }

  public static asDate(date: Timestamp | Date) {
    if (date instanceof Date) {
      return date;
    }

    return date?.toDate();
  }
}
