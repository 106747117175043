import { Component, OnInit, Input } from "@angular/core";
import { getImageUrl } from "../../image";

@Component({
  selector: "app-fire-img",
  template: `
    <img [lazyLoad]="getImage(src)" [ngClass]="imgClass" />
  `,
  styles: [
    `
      .img-thumbnail {
        height: 120px;
        max-width: initial;
      }

      @media print {
        .img-thumbnail {
          max-width: 25px;
          max-height: 25px;
        }
      }
    `
  ],
})
export class FireImgComponent implements OnInit {
  @Input()
  public src: string;

  @Input()
  public imgClass: string | string[] |  Record<string, any>  = "img-thumbnail";

  constructor() {}

  ngOnInit() {}

  getImage = getImageUrl;
}
