import { clone } from "lodash";
import { Injectable } from "@angular/core";
import * as moment from "moment-mini";
import { OpeningHoursProvider } from "./../data/opening-hours.provider";
import { OpeningHours } from "./../model/opening-hours";

@Injectable()
export class OpeningHoursService {
  constructor(private openingHoursProvider: OpeningHoursProvider) {}

  public getHoursForDay(day: moment.Moment): OpeningHours {
    return this.openingHoursProvider.getDay(day);
  }

  public getOrderedWeekHours(hours: OpeningHours): OpeningHours[] {
    let week = this.openingHoursProvider.getWeek();
    let dayNum = week.indexOf(hours);

    let orderedWeek = clone(week);
    let weekEnd = orderedWeek.splice(dayNum);
    orderedWeek = weekEnd.concat(orderedWeek);

    return orderedWeek;
  }

  public nextOpenDay(currentDay: moment.Moment): OpeningHours {
    let day = moment(currentDay);

    let week = this.openingHoursProvider.getWeek();

    if (this.willBeOpen(day)) {
      return this.getHoursForDay(day);
    }

    let nextOpenDay = week[day.day()];
    for (let i = 0; i < 7; i++) {
      day.add(1, "day");
      let hours = week[day.day()];

      let isOpenThisDay = hours && hours.opensAt && !this.isPublicHoliday(day);
      if (isOpenThisDay) {
        nextOpenDay = hours;
        break;
      }
    }

    return nextOpenDay;
  }

  public isPublicHoliday(now: moment.Moment) {
    return this.openingHoursProvider.isPublicHoliday(now);
  }

  public isOpen(now: moment.Moment): boolean {
    let todaysHours: OpeningHours = this.openingHoursProvider.getDay(now);

    if (!todaysHours || !todaysHours.opensAt || this.isPublicHoliday(now)) {
      return false;
    }

    let opensAt = this.createMomentToday(now, todaysHours.opensAt);
    let closesAt = this.createMomentToday(now, todaysHours.closesAt);

    return now.isAfter(opensAt) && now.isBefore(closesAt);
  }

  public willBeOpen(now: moment.Moment): boolean {
    let todaysHours: OpeningHours = this.openingHoursProvider.getDay(now);

    if (!todaysHours || !todaysHours.opensAt || this.isPublicHoliday(now)) {
      return false;
    }

    let closesAt = this.createMomentToday(now, todaysHours.closesAt);
    return now.isBefore(closesAt);
  }

  private createMomentToday(now: moment.Moment, timeString: string) {
    let time = moment(timeString, "h:mm A");
    return moment(now).set({
      hour: time.hour(),
      minute: time.minute(),
      second: time.second()
    });
  }
}
