import { Injectable } from "@angular/core";
import { RouterQuery } from "@datorama/akita-ng-router-store";
import { combineLatest } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { FirebaseQuery } from "../firebase.query";
import { ProductService } from "./product.service";
import { ProductState, ProductStore } from "./product.store";

@Injectable({ providedIn: "root" })
export class ProductQuery extends FirebaseQuery<ProductState> {
  public categoryPath$ = this.routerQuery.selectParams<string>().pipe(
    map(({ category, subcategory, subsubcategory }) => {
      return ProductQuery.getPath([category, subcategory, subsubcategory]);
    })
  );

  public roleId$ = this.routerQuery.selectParams<string>().pipe(map(({ roleId }) => roleId));

  public groupPageId$ = this.routerQuery.selectParams<string>().pipe(map(({ grouppage }) => grouppage));

  public categoryProducts$ = combineLatest([this.categoryPath$, this.roleId$, this.groupPageId$]).pipe(
    switchMap(([path, roleId, groupPageId]) =>
      this.selectAll({
        filterBy: product => {
          if (path) return product.category === path;
          else if (roleId) return product.roles?.includes(roleId);
          else if (groupPageId) return product.groupPages?.includes(groupPageId);
          else return false;
        },
        sortBy: "brand"
      })
    )
  );

  public groupPageProducts$ = this.groupPageId$.pipe(
    switchMap(groupPageId =>
      this.selectAll({
        filterBy: product => product.groupPages?.includes(groupPageId),
        sortBy: "brand"
      })
    )
  );

  public latestProducts$ = this.selectAll({
    limitTo: 6,
    filterBy: product => product.category !== "portal" && (!environment.production || product.category !== "test"),
    sortBy: product => product.updated || product.created
  });

  constructor(protected store: ProductStore, protected service: ProductService, private routerQuery: RouterQuery) {
    super(store, service);
  }

  public static getPath(paths: string[]) {
    return paths.filter(_ => _).join("/");
  }
}
