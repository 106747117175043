<header id="header-area">
  <div class="header-top">
    <div class="container-fluid">
      <div class="col-xs-12">
        <div class="header-links">
          <ul class="nav navbar-nav pull-left" style="width: 100%">
            <ng-container *ngIf="hideHeader$ | async">
              <a routerLink="/">
                <img
                  [src]="appDetails.logo"
                  class="title-logo sm-title-logo img-responsive"
                  [title]="appDetails.name"
                  [alt]="appDetails.name"
                />
              </a>
            </ng-container>
            <ng-container *ngIf="!(hideHeader$ | async)">
              <li>
                <a href="tel:{{ appDetails.phone }}" title="Call us on {{ appDetails.phoneFormatted }}">
                  <i class="fa fa-phone"></i>
                  <span class="hidden-sm hidden-xs"> {{ appDetails.phoneFormatted }} </span>
                </a>
              </li>
              <li *ngIf="appDetails.addressHref">
                <a [href]="appDetails.addressHref" target="_blank" rel="noopener noreferrer">
                  <i class="fa fa-map-marker" [title]="appDetails.addressFormatted"></i>
                  <span class="hidden-sm hidden-xs"> {{ appDetails.addressFormatted }} </span>
                </a>
              </li>
              <li>
                <a>
                  <span>
                    <opening-hours></opening-hours>
                  </span>
                </a>
              </li>
            </ng-container>
            <button
              class="navbar-toggler hidden-sm-up pull-right"
              type="button"
              (click)="open = !open"
              *ngIf="!(hideHeader$ | async)"
            >
              <span class="sr-only">Toggle Category List</span>
              <i class="fa fa-bars"></i>
            </button>
            <ng-container *ngIf="user$ | async as user; else notLoggedIn">
              <li class="pull-right">
                <a routerLink="/portal/home">
                  <span class="hidden-xs">{{ "Hi, " + (user.displayName || user.email) + "!" }}</span>
                  <i class="fa fa-shopping-cart"></i>
                  <span class="hidden-xs">Order Portal</span>
                </a>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="!(hideHeader$ | async)">
    <div class="main-header">
      <div class="row vertical-align">
        <div class="col-xs-6">
          <a routerLink="/">
            <img
              [src]="appDetails.logo"
              class="title-logo img-responsive"
              [title]="appDetails.name"
              [alt]="appDetails.name"
            />
          </a>
        </div>
        <div class="col-xs-6 col-sm-4 col-sm-offset-2">
          <div id="search">
            <div class="input-group">
              <label for="searchInput" class="sr-only">Search</label>
              <input
                id="searchInput"
                [(ngModel)]="searchText"
                (valueChanged)="search(searchText)"
                (customSelected)="search(searchText)"
                [open-on-focus]="false"
                type="text"
                class="form-control"
                placeholder="Search..."
                ngui-auto-complete
                [source]="searchSuggestions"
                max-num-list="5"
                (keyup.enter)="search(searchText)"
              />
              <div class="input-group-btn">
                <button class="btn" type="button" (click)="search(searchText)" aria-label="Search">
                  <i class="fa fa-search"></i>
                  <span class="sr-only">Search</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <nav id="main-menu" class="navbar" role="navigation">
      <div class="navbar-collapse offcanvas-collapse" [ngClass]="{ open: open }">
        <ul class="nav navbar-nav">
          <li *ngFor="let category of categories$ | async" routerLinkActive="active">
            <a [routerLink]="['/category/', category.path]">{{ category.name }}</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>

<ng-template #notLoggedIn>
  <li class="pull-right">
    <a routerLink="/portal/home">
      <i class="fa fa-user"></i>
      <span class="hidden-xs">Login</span>
    </a>
  </li>
</ng-template>
