<div class="product-col zoom">
  <a class="featured-image-link" *ngIf="routerLink" [routerLink]="routerLink">
    <span class="sr-only">{{ category.name }}</span>
    <div class="thumb">
      <div class="thumb-image">
        <div class="featured-image" [ngClass]="{ contain: imageContain }" [lazyLoad]="getImage(category.image)"></div>
      </div>
    </div>
    <div class="thumb-info">
      <div class="thumb-title">
        <div class="thumb-name">
          <h2>
            {{ category.name }}
          </h2>
        </div>
      </div>
    </div>
  </a>
  <a class="featured-image-link" *ngIf="!routerLink" [href]="link" [target]="newWindow ? '_blank' : '_self'">
    <span class="sr-only">{{ category.name }}</span>
    <div class="thumb">
      <div class="thumb-image">
        <div class="featured-image" [ngClass]="{ contain: imageContain }" [lazyLoad]="getImage(category.image)"></div>
      </div>
    </div>
    <div class="thumb-info">
      <div class="thumb-title">
        <div class="thumb-name">
          <h2>
            {{ category.name }}
          </h2>
        </div>
      </div>
    </div>
  </a>
</div>
