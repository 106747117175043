import { QueryEntity, EntityState, EntityStore } from "@datorama/akita";
import { OnDestroy } from "@angular/core";
import { QueryFn } from "@angular/fire/firestore";
import { FirebaseService } from "./firebase.service";
import { DocOptions, SyncOptions } from "akita-ng-fire";

export class FirebaseQuery<S extends EntityState> extends QueryEntity<S> {
         constructor(protected store: EntityStore<S>, protected service: FirebaseService<S>) {
           super(store);
         }

         syncActive(options: S["active"] extends any[] ? string[] : DocOptions, syncOptions?: Partial<SyncOptions>) {
           return this.service.syncActive(options, syncOptions);
         }

         syncCollectionUntilDestroyed(component: OnDestroy, query?: QueryFn) {
           return this.service.syncCollectionUntilDestroyed(component, query);
         }

         syncCollection(query?: QueryFn) {
           return this.service.syncCollection(query, { reset: !!query });
         }

         syncOnce(query?: QueryFn) {
           return this.service.syncCollectionOnce(query);
         }

         clearActive() {
           this.store.setActive(null);
         }

         setActive(options: S["active"]) {
           this.store.setActive(options);
         }
       }
