import { Injectable } from "@angular/core";
import { CollectionConfig } from "akita-ng-fire";
import { FirebaseService } from "../firebase.service";
import { CategoriesState, CategoriesStore } from "./categories.store";

@Injectable({ providedIn: "root" })
@CollectionConfig({ path: "categories" })
export class CategoriesService extends FirebaseService<CategoriesState> {
  constructor(store: CategoriesStore) {
    super(store);
  }
}
