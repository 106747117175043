import { Category } from "./../model/category";
import { Component, OnInit, Input } from "@angular/core";
import { getImageUrl } from "../image";

@Component({
  selector: "category-card",
  templateUrl: "./category-card.component.html",
  styleUrls: ["./category-card.component.scss"]
})
export class CategoryCardComponent implements OnInit {
  @Input()
  public link: string = "#";

  @Input()
  public routerLink: string | any[];

  @Input()
  public category: Category;

  @Input()
  public newWindow?: boolean;

  @Input()
  public imageContain?: boolean;

  constructor() {}

  ngOnInit() {}

  getImage = getImageUrl;
}
