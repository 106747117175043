import { Component, OnInit } from "@angular/core";
import { OpeningHoursService } from "./opening-hours.service";
import { OpeningHours } from "../model/opening-hours";
import * as moment from "moment-mini";

@Component({
  selector: "opening-hours",
  inputs: ["interactive"],
  templateUrl: "./opening-hours.component.html",
  styleUrls: ["./opening-hours.component.scss"]
})
export class OpeningHoursComponent implements OnInit {
  public now: moment.Moment;
  public nowDate: Date;
  
  public today: OpeningHours;
  public nextOpen: OpeningHours;
  public week: OpeningHours[];
  public showAllHours: boolean;

  public interactive: boolean = true;

  constructor(private openingHoursService: OpeningHoursService) {
    this.now = moment();
    this.nowDate = this.now.toDate();

    this.today = openingHoursService.getHoursForDay(this.now);
    this.nextOpen = openingHoursService.nextOpenDay(this.now);
    this.week = openingHoursService.getOrderedWeekHours(this.nextOpen);
  }

  ngOnInit() {}

  public toggleShowHours() {
    this.showAllHours = !this.showAllHours;
  }

  public isOpen() {
    return this.openingHoursService.isOpen(this.now);
  }

  public willOpenToday() {
    return this.openingHoursService.willBeOpen(this.now);
  }

  public getHint(): string {
    if (this.isOpen()) {
      return "Open now ";
    } else if (this.willOpenToday()) {
      return "Opens soon ";
    }

    return "Closed. Opens ";
  }

  public getMobileHours(hours: OpeningHours) {
    if (this.isOpen()) {
      return "Open till " + hours.closesAt;
    } else if (this.willOpenToday()) {
      return "Opens at " + hours.opensAt;
    }

    return "Closed. Opens " + hours.opensAt + " " + hours.day.slice(0, 3);
  }

  public getOpeningHours(hours: OpeningHours): string {
    if (hours.opensAt) {
      return hours.opensAt + "-" + hours.closesAt;
    }

    return "Closed";
  }
}
