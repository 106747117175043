import { Pipe, PipeTransform } from "@angular/core";

/**
 * Sorts an array to match the order of the "example" array
 */
@Pipe({
  name: "exampleSort"
})
export class ExampleSortPipe implements PipeTransform {
  transform<T>(array: T[], example: string[], field?: string): T[] {
    if (!array) {
      return [];
    }

    return array.sort((a, b) => {
      if (a[field] && b[field]) {
        return example.indexOf(a[field]) - example.indexOf(b[field]);
      }
      return example.indexOf(<any>a) - example.indexOf(<any>b);
    });
  }
}
