import { TSMap } from "typescript-map";
import { Category } from "./../model/category";
import { schoolPricelists } from "./categories/school/school-pricelists";
import { environment } from "../../environments/environment";
import { Pdfs } from "../../assets/pdfs/pdfs";

export var subcategories: TSMap<string, Category[]> = new TSMap<string, Category[]>();

subcategories.set("workwear", [
  {
    name: "Hi-Vis",
    path: "hi-vis",
    image: "categories/WORKWEAR HI VIS.jpg"
  },
  {
    name: "Non Hi-Vis",
    path: "non-hi-vis",
    image: "categories/Non HI Vis workwear header.jpg"
  },
  {
    name: "Flame Resistant",
    path: "flame-resistant",
    image: "categories/FR Category Header.jpg"
  },
  {
    name: "Womens",
    path: "womens",
    image: "categories/WORKWEAR Womens.jpg"
  },
  {
    name: "Kids",
    path: "kids",
    image: "categories/Kids Workwear.jpg"
  }
]);

subcategories.set("work-boots", [
  {
    name: "Safety",
    path: "safety",
    image: "headers/workboots-safety.jpg"
  },
  {
    name: "Non-Safety",
    path: "non-safety",
    image: "headers/workboots-nonsafety.jpg"
  },
  {
    name: "Women's Boots",
    path: "womens",
    image: "headers/womens-boots.jpg"
  },
  {
    name: "Socks",
    path: "socks",
    image: "headers/socks.jpg"
  }
]);

subcategories.set("ppe", [
  {
    name: "Eye Protection",
    path: "eye-protection",
    image: "headers/EYE PROTECTION 2.jpg"
  },
  {
    name: "Hand Protection",
    path: "hand-protection",
    image: "headers/Hand PROTECTION.png"
  },
  {
    name: "Head Protection",
    path: "head-protection",
    image: "headers/PPE.png"
  },
  {
    name: "Hearing Protection",
    path: "hearing-protection",
    image: "headers/Ear PROTECTION.png"
  }
]);

subcategories.set("sportswear", [
  {
    name: "Playing Gear",
    path: "playing-gear",
    image: "sportswear-playing.jpg"
  },
  {
    name: "Aftergame Gear",
    path: "aftergame-gear",
    image: "sports-after.jpg"
  },
  // {
  //   name: 'ProTeam Custom Dye-Sublimation',
  //   path: 'proteam',
  //   image: 'tradie.jpg'
  // },
]);

subcategories.set("school", [
  {
    name: "Uniform Pricelists",
    path: "uniform-pricelists",
    image: "headers/Uniform Price lists.jpg"
  },
  {
    name: "Leavers",
    image: "Leavers Header 2021.jpg",
    newWindow: true,
    ...Pdfs.TU2021leaverscataloguev1
  },
  // {
  //   name: "Champion Bay Senior High School",
  //   image: "schools/cbshs.png",
  //   path: "champion-bay",
  // },
  // {
  //   name: "Geraldton Senior High School",
  //   image: "schools/gshs.png",
  //   path: "gshs",
  // },
  // {
  //   name: "Nagle Catholic College",
  //   image: "schools/nagle.png",
  //   path: "nagle",
  // },
  // {
  //   name: "Bluff Point Primary",
  //   image: "schools/bps.png",
  //   path: "bps",
  // },
  // {
  //   name: "Geraldton Primary",
  //   image: "schools/gps.png",
  //   path: "gps",
  // },
  // {
  //   name: "St Francis Xavier Primary",
  //   image: "schools/sfx.png",
  //   path: "sfx",
  // },
  // {
  //   name: "St Lawrence Primary",
  //   image: "schools/stlawrence.png",
  //   path: "stlawrence",
  // },
  // {
  //   name: "Wandina Primary",
  //   image: "schools/wandina.png",
  //   path: "wandina",
  // },
  // {
  //   name: "Mount Tarcoola Primary",
  //   image: "schools/mtp.png",
  //   path: "mtp",
  // },
  {
    name: "Shoes",
    path: "shoes",
    image: "shoes.jpg"
  },
  {
    name: "Backpacks and Bags",
    path: "bags-backpacks",
    image: "bag.webp"
  }
]);

subcategories.set("school-shoes-and-bags", []);

subcategories.set("polos-and-tees", [
  {
    name: "Mens",
    path: "mens",
    image: "Mens polo header.jpg"
  },
  {
    name: "Womens",
    path: "ladies",
    image: "Ladies polo header.jpg"
  },
  {
    name: "Kids",
    path: "kids",
    image: "Kids polo header.jpg"
  }
]);

subcategories.set("office", [
  {
    name: "Mens",
    path: "mens",
    image: "categories/OFFICE MENS  SUITING.jpg"
  },
  {
    name: "Womens",
    path: "ladies",
    image: "categories/OFFICE WOMENS SUITING.jpg"
  }
]);

export var categories: Category[] = [
  {
    name: "Workwear",
    path: "workwear",
    image: "tradies.jpg"
  },
  {
    name: "Boots",
    path: "work-boots",
    image: "headers/work-boots-header.png"
  },
  {
    name: "PPE",
    path: "ppe",
    image: "headers/PPE.png"
  },
  {
    name: "Office",
    path: "office",
    image: "categories/OFFICE header.jpg"
  },
  {
    name: "Health & Beauty",
    path: "health-and-beauty",
    image: "healthcare.jpg"
  },
  {
    name: "Cafe & Chef",
    path: "cafe-and-chef",
    image: "cafenchef.jpg"
  },
  {
    name: "Polo's & Tee's",
    path: "polos-and-tees",
    image: "categories/Polos and Ts Header.jpg"
  },
  {
    name: "Sport",
    path: "sportswear",
    image: "sports-active.jpg"
  },
  {
    name: "School",
    path: "school",
    image: "school.jpg"
  }
];

subcategories.set("workwear/hi-vis", [
  {
    name: "Shirts",
    path: "shirts",
    image: "categories/WORKWEAR HI VIS SHIRTS.jpg"
  },
  {
    name: "Pants",
    path: "pants",
    image: "categories/WORKWEAR HIVIS PANTS.jpg"
  },
  {
    name: "Jackets and Hoodies",
    path: "jackets-and-hoodies",
    image: "categories/WORKWEAR HI VIS Jackets and Hoodies.jpg"
  }
  // {
  //   name: "Coveralls and Vests",
  //   path: "coveralls-vests",
  //   image: "headers/Womens Office.jpg"
  // }
]);

subcategories.set("workwear/non-hi-vis", [
  {
    name: "Shirts",
    path: "shirts",
    image: "categories/WORKWEAR NON HI VIS SHIRTS.jpg"
  },
  {
    name: "Pants and Shorts",
    path: "pants-shorts",
    image: "categories/WORKWEAR NON HI VIS PANTS and SHORTS.jpg"
  },
  {
    name: "Jackets and Hoodies",
    path: "jackets-and-hoodies",
    image: "categories/WORKWEAR NON HI VIS - JACKET and HOODIES.jpg"
  }
  // {
  //   name: "Coveralls and Vests",
  //   path: "coveralls-vests",
  //   image: "headers/Womens Office.jpg"
  // }
]);

subcategories.set("office/mens", [
  {
    name: "Shirts",
    path: "shirts",
    image: "categories/OFFICE MENS SHIRTS.jpg"
  },
  {
    name: "Pants",
    path: "pants",
    image: "categories/OFFICE MENS  PANTS.jpg"
  },
  {
    name: "Suiting",
    path: "suiting",
    image: "categories/OFFICE MENS  SUITING.jpg"
  },
  {
    name: "Jackets and Knits",
    path: "jackets-knits",
    image: "categories/OFFICE MENS JACKETS and KNITS.jpg"
  },
  {
    name: "Accessories",
    path: "accessories",
    image: "categories/OFFICE MENS ACCESSORIES.jpg"
  }
]);

subcategories.set("office/ladies", [
  {
    name: "Blouses",
    path: "blouses",
    image: "categories/OFFICE WOMENS BLOUSE.jpg"
  },
  {
    name: "Skirts and Pants",
    path: "pants",
    image: "categories/OFFICE WOMENS SKIRTS and PANTS.jpg"
  },
  {
    name: "Suiting",
    path: "suiting",
    image: "categories/OFFICE WOMENS SUITING.jpg"
  },
  {
    name: "Jackets and Knits",
    path: "jackets-knits",
    image: "categories/OFFICE WOMENS JACKETS and KNITS.jpg"
  },
  {
    name: "Accessories",
    path: "accessories",
    image: "categories/OFFICE WOMENS ACCESSORIES.jpg"
  }
]);

subcategories.set("health-and-beauty", [
  {
    name: "Scrubs",
    path: "scrubs",
    image: "categories/HEALTH and BEAUTY SCRUBS.jpg"
  },
  {
    name: "Tunics",
    path: "tunics",
    image: "categories/HEALTH and BEAUTY TUNICS.jpg"
  }
  // {
  //   name: "Footwear",
  //   path: "footwear",
  //   image: "categories/OFFICE WOMENS BLOUSE.jpg"
  // }
]);

subcategories.set("cafe-and-chef", [
  {
    name: "Chef Jackets and Pants",
    path: "chef-jackets",
    image: "categories/Cafe and Chef CHEF JACKETS and Pants.jpg"
  },
  {
    name: "Aprons",
    path: "aprons",
    image: "categories/Cafe and Chef Aprons.jpg"
  },
  {
    name: "Knives",
    path: "knives",
    image: "categories/Cafe and Chef Knives.jpg"
  }
  // {
  //   name: "Footwear",
  //   path: "footwear",
  //   image: "categories/OFFICE WOMENS BLOUSE.jpg"
  // }
]);
if (environment.toggles.testCategories) {
  categories.push({
    name: "Test",
    path: "test",
    image: "school.jpg",
    test: true
  });
}

categories.forEach(category => {
  category.fullPath = "/category/" + category.path;
});

subcategories.forEach((categories, key) => {
  const paths = key.split("/");
  const parentPath = paths[paths.length - 1];
  categories.forEach(category => {
    category.parentPath = category.parentPath || parentPath;
    category.fullPath = category.fullPath || ["/category", key, category.path].join("/");
  });
});

export var allCategories: Category[] = [...categories, ...schoolPricelists, ...[].concat(...subcategories.values())];
