export const environmentDefault: PortalEnvironment = {
  envName: "dev",
  production: false,
  useHash: false,
  hmr: false,
  toggles: {},
  details: {
    name: "Total Uniforms",
    deployedUrl: "http://www.totaluniforms.com.au/",
    phone: "0899653101",
    phoneFormatted: "(08) 99 653 101",
    email: "customerservice@totaluniforms.com.au",
    addressFormatted: "2B Jensen St, Geraldton",
    addressHref: "https://www.google.com.au/maps/place/2B+Jensen+St,+Geraldton+WA+6530",
    facebookHref: "https://www.facebook.com/Total-Uniforms-316774165171951/",
    logo: "assets/images/total-uniforms-logo-2023-sm.png",
    logoSm: "/assets/favicon/apple-icon.png",
    location: {
      lat: -28.773299,
      lng: 114.62479
    }
  },
  googleMapsKey: "AIzaSyA2SOyXJBYkXxFk1pKErR1FgJSZAjZ8zpk",
  firebase: {
    apiKey: "AIzaSyDt0lPBsa3LLCsvtx1ju-35yj87bYBSqOQ",
    authDomain: "total-uniforms.firebaseapp.com",
    databaseURL: "https://total-uniforms.firebaseio.com",
    projectId: "total-uniforms",
    storageBucket: "total-uniforms.appspot.com",
    messagingSenderId: "275881585664",
    appId: "1:275881585664:web:c07337614e76258d563ce5",
    measurementId: "G-C3J9QTVJDP"
  },
  aws: {
    imageBucket: "https://d12q7a9hxw2wso.cloudfront.net"
  }
};

export const demoEnvironment: PortalEnvironment = {
  envName: "portal-demo",
  production: false,
  hmr: true,
  useHash: false,
  toggles: {
    addCategoriesToggle: true,
    testCategories: true,
    testUsers: true,
    groupPagePortal: true
  },
  details: {
    name: "Order Portal",
    phone: "+61861181858",
    phoneFormatted: "(08) 6118 1858",
    email: "support@resourcefulsoftware.com.au",
    addressFormatted: "2B Jensen St, Geraldton",
    addressHref: "https://www.google.com.au/maps/place/2B+Jensen+St,+Geraldton+WA+6530",
    facebookHref: "https://www.linkedin.com/company/resourceful-software/",
    logo: "assets/images/order-portal-logo.png",
    logoSm: "/assets/favicon/portal/apple-icon.png",
    location: {
      lat: -28.773299,
      lng: 114.62479
    },
    deployedUrl: "https://order-portal-app-demo.web.app/"
  },

  firebase: {
    apiKey: "AIzaSyAaW0keX4cKqK_y-HldjAe1s5LC5arBAo0",
    authDomain: "order-portal-demo-77c81.firebaseapp.com",
    databaseURL: "https://order-portal-demo-77c81-default-rtdb.firebaseio.com",
    projectId: "order-portal-demo-77c81",
    storageBucket: "order-portal-demo-77c81.appspot.com",
    messagingSenderId: "605204169629",
    appId: "1:605204169629:web:ffb8e091b28413479d6574",
    measurementId: "G-VX1E2C9LNM"
  },

  aws: {
    imageBucket: "https://d12q7a9hxw2wso.cloudfront.net"
  },

  googleMapsKey: "AIzaSyA2SOyXJBYkXxFk1pKErR1FgJSZAjZ8zpk"
};

export interface PortalEnvironment {
  envName: string;
  production: boolean;
  useHash: boolean;
  hmr: boolean;
  toggles: {
    devTools?: boolean;
    addCategoriesToggle?: boolean;
    testCategories?: boolean;
    testUsers?: boolean;
    groupPagePortal?: boolean;
  };
  details: {
    name: string;
    deployedUrl: string;
    phone: string;
    phoneFormatted: string;
    email: string;
    addressFormatted: string;
    addressHref: string;
    facebookHref: string;
    logo: string;
    logoSm: string;
    location: {
      lat: number;
      lng: number;
    };
  };
  logRocketProject?: string;
  sentryDsn?: string;
  googleMapsKey: string;
  firebase: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
  aws: {
    imageBucket: string;
  };
}
