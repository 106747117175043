import { environment } from "./../environments/environment";
import { VERSION } from "./../environments/version";
import { FooterBarComponent } from "./footer-bar/footer-bar.component";
import { RoutesService } from "./routes/routes.service";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, Injectable, ErrorHandler } from "@angular/core";
import { LazyLoadImageModule, intersectionObserverPreset } from "ng-lazyload-image";

import { AppRoutingModule } from "./routes/routing.module";

import { AppComponent } from "./app.component";
import { NavigationComponent } from "./navigation/navigation.component";
import { AngularFireModule } from "@angular/fire";
import { InlineStyleComponent } from "./inline-style/inline-style.component";
import { InlineStyleModule } from "./inline-style/inline-style.module";
import { SharedModule } from "./shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AnalyticsService } from "./analytics.service";

import * as Sentry from "@sentry/angular";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AkitaNgDevtools } from "@datorama/akita-ngdevtools";
import { AkitaNgRouterStoreModule } from "@datorama/akita-ng-router-store";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { NguiAutoCompleteModule } from "@ngui/auto-complete";
import { ServiceWorkerModule } from "@angular/service-worker";
import { HttpErrorResponse } from "@angular/common/http";
import { FormlyModule } from "@ngx-formly/core";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import * as LogRocket from "logrocket";

if (environment.sentryDsn) {
  Sentry.init({
    release: `${VERSION.version}-${VERSION.hash}`,
    dsn: environment.sentryDsn,
    environment: environment.envName,
    integrations: [
      new Sentry.Integrations.TryCatch({
        XMLHttpRequest: false
      })
    ]
  });
}

if (environment.logRocketProject) {
  LogRocket.init(environment.logRocketProject, {
    release: `${VERSION.version}-${VERSION.hash}`
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}

  extractError(error) {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
    }

    if (typeof error === "string" || error instanceof Error) {
      return error;
    }

    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof Error) {
        return error.error;
      }

      if (error.error instanceof ErrorEvent) {
        return error.error.message;
      }

      if (typeof error.error === "string") {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }
      return error.message;
    }
    return error.originalError || error;
  }

  handleError(error) {
    let extractedError = this.extractError(error) || "Handled unknown error";

    if (!environment.production) {
      console.error(extractedError);
      return;
    }

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
      return;
    }

    Sentry.captureException(extractedError);
    throw error;
  }
}

@NgModule({
  declarations: [AppComponent, NavigationComponent, FooterBarComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "portal" }),
    BrowserAnimationsModule,
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset
    }),
    FormsModule,
    InlineStyleModule,
    SharedModule,
    AppRoutingModule,
    NguiAutoCompleteModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    environment.toggles.devTools ? AkitaNgDevtools.forRoot() : [],
    AkitaNgRouterStoreModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production }),
    ReactiveFormsModule,
    FormlyModule.forRoot({ extras: { lazyRender: true } }),
    FormlyBootstrapModule
  ],
  providers: [RoutesService, AnalyticsService, { provide: ErrorHandler, useClass: SentryErrorHandler }],
  bootstrap: [AppComponent, InlineStyleComponent]
})
export class AppModule {}
