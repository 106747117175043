import { routes } from "./routes";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { environment } from "../../environments/environment";

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      scrollPositionRestoration: "top",
      initialNavigation: "enabled"
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
