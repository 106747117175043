import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { Product } from "./model/product";
import { Title } from "@angular/platform-browser";
import { isPlatformBrowser } from "@angular/common";
import * as Sentry from "@sentry/angular";
import { environment } from "../environments/environment";
import { WebEventsService } from "./state/webevents/webevents.service";
import { WebEvent } from "./state/webevents/webevent.model";

declare let ga: Function;

@Injectable({
  providedIn: "root"
})
export class AnalyticsService {
  constructor(
    private titleService: Title,
    @Inject(PLATFORM_ID) private platformId: {},
    private webEventsService: WebEventsService
  ) {}

  public pageView() {
    if (!this.isBrowser()) {
      return;
    }

    Sentry.addBreadcrumb({
      category: "nav",
      message: "Navigated to " + window.location.pathname
    });

    ga("set", "page", window.location.pathname);
    ga("send", {
      hitType: "pageview",
      title: this.titleService.getTitle()
    });
  }

  viewCategory(name: string) {
    if (!this.isBrowser()) {
      return;
    }

    Sentry.addBreadcrumb({ message: "Viewed category " + name });

    ga("send", "event", "Category", "view", name);
  }

  viewProduct(product: Product) {
    if (!this.isBrowser()) {
      return;
    }

    Sentry.addBreadcrumb({ message: "Viewed product " + product.code });

    ga("send", "event", "Product", "view", product.code, {
      code: product.code,
      userAgent: navigator.userAgent,
      date: Date.now()
    });
  }

  logException(error?: Error) {
    if (!this.isBrowser() || !error) {
      return;
    }

    console.error(error);

    Sentry.captureException(error);

    ga("send", "exception", {
      exDescription: error && error.message ? error.message : JSON.stringify(error),
      exFatal: false
    });
  }

  addBreadcrumb(breadcrumb: string | Sentry.Breadcrumb) {
    breadcrumb = typeof breadcrumb === "string" ? { message: breadcrumb } : breadcrumb;

    if (!environment.production) {
      console.log(breadcrumb.message);
    }

    Sentry.addBreadcrumb(breadcrumb);
  }

  async clickButton(buttonName: string, additional: any = {}): Promise<string> {
    if (!environment.production) {
      return;
    }

    const user = this.webEventsService.getUser();
    const event: WebEvent<any> = {
      type: "button-click",
      environment: environment.envName,
      data: {
        buttonName,
        user,
        ...additional
      },
      createdBy: user.email || "",
      date: new Date()
    };
    this.addBreadcrumb(`Clicked button ${buttonName}`);
    return this.webEventsService.add(event);
  }

  private isBrowser() {
    return isPlatformBrowser(this.platformId);
  }
}
