import { environmentDefault, PortalEnvironment } from "./environment.default";

export const environment: PortalEnvironment = {
  ...environmentDefault,

  envName: "prod",
  production: true,
  hmr: false,
  useHash: false,

  logRocketProject: "e7brmv/total-uniforms-prod",
  sentryDsn: "https://55cb35eefb8c4f1eb2428a05c25a5bfd@sentry.io/1530181"
};
