import { Product } from "./../model/product";

import { Component, OnInit, Input } from "@angular/core";
import { Observable } from "rxjs";
import { getImageUrl } from "../image";

@Component({
  selector: "product-card",
  templateUrl: "./product-card.component.html",
  styleUrls: ["./product-card.component.scss"]
})
export class ProductCardComponent implements OnInit {
  @Input()
  public product: Product;

  $image: Observable<string>;

  constructor() {}

  ngOnInit() {}

  getImage = getImageUrl
}
