import { EntityState, EntityStore, getEntityType } from "@datorama/akita";
import { CollectionService, WriteOptions } from "akita-ng-fire";
import { QueryFn } from "@angular/fire/firestore";
import { untilDestroyed } from "ngx-take-until-destroy";
import { OnDestroy } from "@angular/core";
import { take, catchError, tap } from "rxjs/operators";
import { throwError } from "rxjs";
import * as Sentry from "@sentry/angular";

export class FirebaseService<
  S extends EntityState<EntityType, string>,
  EntityType = getEntityType<S>
> extends CollectionService<S, EntityType> {
  constructor(store: EntityStore<S>) {
    super(store);
  }

  protected onDelete(id: string) {
    Sentry.addBreadcrumb({ message: `[${this.path}] Deleted  ${id}` });
    this.store.remove(id as any);
  }

  async add<D extends Partial<EntityType> | Partial<EntityType>[]>(
    documents: D,
    options?: WriteOptions
  ): Promise<D extends (infer _I)[] ? string[] : string> {
    const docs: EntityType[] = <EntityType[]>(Array.isArray(documents) ? documents : [documents]);
    docs.forEach(doc => {
      this.ensureId(doc);
      FirebaseService.removeNulls(doc);
    });

    await super.add(docs, options);
    Sentry.addBreadcrumb({ message: `[${this.path}] Added documents` });
    return Array.isArray(documents) ? (<any[]>documents).map(d => d[this.idKey]) : documents[this.idKey];
  }

  getActive(id: S["active"]) {
    this.store.setActive(id);
    return this.collection
      .doc(id)
      .get()
      .pipe(
        tap(doc => {
          if (!doc.exists) {
            const err = `Could not find product: ${id}`;
            console.error(err);
            this.store.setLoading(false);
            this.store.setError(err);
            return;
          }

          Sentry.addBreadcrumb({ message: `[${this.path}] Loaded active ${id}` });
          const entity = { ...(doc.data() as any), [this.idKey]: id };
          this.store.add(entity, { loading: false });
        }),
        catchError(err => {
          console.error(err);
          this.store.setError(err);
          this.store.setLoading(false);
          return throwError(err);
        })
      );
  }

  syncCollectionUntilDestroyed(component: OnDestroy, query?: QueryFn) {
    return this.syncCollection(query, { reset: !!query })
      .pipe(untilDestroyed(component))
      .subscribe();
  }

  syncCollectionOnce(query?: QueryFn) {
    return this.syncCollection(query)
      .pipe(take(1))
      .subscribe();
  }

  private ensureId<T>(doc: T): void {
    if (!doc[this.idKey]) {
      doc[this.idKey] = this.db.createId();
    }
  }

  public static removeNulls<T>(doc: T): T {
    for (const attr in doc) {
      if (doc[attr] === null || doc[attr] === undefined) {
        delete doc[attr];
      }
    }
    return doc
  }

  getUser(): Sentry.User {
    try {
      const userString = window.localStorage && localStorage.getItem("user");
      if (userString) {
        const sentryUser: Sentry.User = JSON.parse(userString);
        return sentryUser;
      }
    } catch (e) {}

    return {};
  }
}
