import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { Category, CategoryInfo } from "../../model/category";
import { FirebaseQuery } from "../firebase.query";
import { CategoriesService } from "./categories.service";
import { CategoriesState, CategoriesStore } from "./categories.store";

@Injectable({ providedIn: "root" })
export class CategoriesQuery extends FirebaseQuery<CategoriesState> {
  public categories$: Observable<Category[]> = this.selectAll({
    filterBy: c => !c.test || environment.toggles.testCategories,
    sortBy: "order"
  });

  public topLevelCategories$: Observable<Category[]> = this.selectAll({
    filterBy: c => !c.parentPath && (!c.test || environment.toggles.testCategories),
    sortBy: "order"
  }).pipe(
    map(categories => {
      return categories.map(c => ({
        ...c,
        fullPath: c.parentPath ? `/category/${c.parentPath}/${c.path}` : `/category/${c.path}`
      }));
    })
  );

  public nestedCategories$: Observable<CategoryInfo[]> = this.topLevelCategories$.pipe(
    map(categories =>
      categories.map(c => ({
        category: c,
        parent: this.getEntity(c.id),
        subcategories$: this.getChildCategories$([c.path])
      }))
    )
  );

  constructor(protected store: CategoriesStore, protected service: CategoriesService) {
    super(store, service);
  }

  public getChildCategories$(categoryPaths: string[]): Observable<CategoryInfo[]> {
    const categoryId = categoryPaths.join("/");
    return this.selectAll({
      filterBy: c => c.parentPath === categoryId && (!c.test || environment.toggles.testCategories),
      sortBy: "order"
    }).pipe(
      map(categories => {
        const categoryInfos: CategoryInfo[] = categories.map(category => {
          return {
            category,
            parent: this.getEntity(categoryId),
            subcategories$: this.getChildCategories$([...categoryPaths, category.path])
          };
        });
        return categoryInfos;
      })
    );
  }
}
