import { Injectable } from "@angular/core";
import { CompanyStore, CompanyState } from "./company.store";
import { CollectionConfig } from "akita-ng-fire";
import { FirebaseService } from "../firebase.service";

@Injectable({ providedIn: "root" })
@CollectionConfig({ path: "companies" })
export class CompanyService extends FirebaseService<CompanyState> {
  constructor(store: CompanyStore) {
    super(store);
  }
}
