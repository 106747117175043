<span [ngClass]="{ 'tu-theme': isOpen() || willOpenToday() }">
  <div class="inline">
    <i class="fa fa-clock-o pull-left" style="margin-top: 3px">&nbsp;</i>
  </div>
  <div class="inline-block">
    <span title="{{ nowDate | date: 'EEEE, h:mma' }}" class="clickable" (click)="toggleShowHours()">
      <span *ngIf="!showAllHours && interactive">
        <span class="visible-xs-block">
          {{ getMobileHours(nextOpen) }}
        </span>
        <span class="hidden-xs">
          {{ getHint() }}<strong *ngIf="!isOpen()">{{ nextOpen.day }}</strong> ({{ getOpeningHours(nextOpen) }})
        </span>
      </span>

      <span *ngIf="showAllHours || !interactive">
        <span *ngFor="let day of week">
          <strong>{{ day.day }}</strong> {{ getOpeningHours(day) }}<br />
        </span>
      </span>
    </span>
  </div>
</span>
