import { Injectable } from "@angular/core";
import { Company } from "./company.model";
import { EntityState, ActiveState, StoreConfig } from "@datorama/akita";
import { FirebaseStore } from "../firebase.store";

export interface CompanyState extends EntityState<Company, string>, ActiveState<string> {}

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "company" })
export class CompanyStore extends FirebaseStore<CompanyState> {
  constructor() {
    super();
  }
}
