import { map, filter } from "rxjs/operators";

import { NavigationComponent } from "./navigation/navigation.component";
import { FooterBarComponent } from "./footer-bar/footer-bar.component";
import { Component, OnInit } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  NavigationCancel,
  NavigationError
} from "@angular/router";
import { AnalyticsService } from "./analytics.service";
import * as Sentry from "@sentry/angular";
import * as LogRocket from "logrocket";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  viewProviders: [NavigationComponent, FooterBarComponent]
})
export class AppComponent implements OnInit {
  loading = false;

  constructor(
    router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private analytics: AnalyticsService,
    private meta: Meta
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.loading = false;
      }
    });
    router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter(route => route.outlet === "primary")
      )
      .subscribe(route => {
        const data = route.snapshot.data;
        let title = data["title"];
        if (title) {
          this.titleService.setTitle(title);
          this.meta.addTags([
            { name: "twitter:card", content: "summary" },
            { name: "og:url", content: window.location.href },
            { name: "og:title", content: title },
            { name: "og:description", content: "" },
            { name: "og:image", content: "" }
          ]);
        }

        analytics.pageView();
      });

    this.restoreUser();
  }

  ngOnInit(): void {}

  private restoreUser() {
    Sentry.setTag("status", "not logged in");
    try {
      const userString = window.localStorage && localStorage.getItem("user");
      if (userString) {
        const sentryUser: Sentry.User = JSON.parse(userString);
        Sentry.configureScope(scope => {
          scope.setUser(sentryUser);
          scope.setTag("username", sentryUser.email);
        });
        LogRocket.identify(sentryUser.id, {
          name: sentryUser.displayName,
          email: sentryUser.email
        });
      }
    } catch (e) {
      this.analytics.logException(e);
    }
  }
}
