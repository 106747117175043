import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sort"
})
export class FieldSortPipe implements PipeTransform {
  transform(array: any[], field: string, reverse?: boolean): any[] {
    const revModifier = reverse ? -1 : 1;

    return array.sort((a, b) => {
      const af = a[field] !== null && a[field] !== undefined ? a[field] : Infinity;
      const bf = b[field] !== null && b[field] !== undefined ? b[field] : Infinity;

      if (af < bf) {
        return -1 * revModifier;
      } else if (af > bf) {
        return 1 * revModifier;
      }

      return 0;
    });
  }
}
