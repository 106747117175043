import { Injectable } from "@angular/core";
import { OpeningHours } from "./../model/opening-hours";
import * as moment from "moment-mini";

@Injectable()
export class OpeningHoursProvider {
  private openingTimes: OpeningHours[] = [
    { day: "Sunday" },
    { day: "Monday", opensAt: "8:30am", closesAt: "5:00pm" },
    { day: "Tuesday", opensAt: "8:30am", closesAt: "5:00pm" },
    { day: "Wednesday", opensAt: "8:30am", closesAt: "5:00pm" },
    { day: "Thursday", opensAt: "8:30am", closesAt: "5:00pm" },
    { day: "Friday", opensAt: "8:30am", closesAt: "5:00pm" },
    { day: "Saturday", opensAt: "9:00am", closesAt: "1:00pm" }
  ];

  private publicHolidays: TimeDate[] = [
    // Fixed dates
    { name: "New Year's Day", date: 1, month: 1 },
    { name: "Australia Day", date: 26, month: 1 },
    { name: "ANZAC Day", date: 25, month: 4 },
    { name: "Christmas Day", date: 25, month: 12 },
    { name: "Boxing Day", date: 26, month: 12 },

    // The transient dates
    { name: "Labour Day", date: 5, month: 3, year: 2018 },
    { name: "Good Friday", date: 30, month: 4, year: 2018 },
    { name: "Easter Monday", date: 2, month: 4, year: 2018 },
    { name: "Western Australia Day", date: 4, month: 6, year: 2018 },
    { name: "Queen's Birthday", date: 24, month: 9, year: 2018 },

    { name: "Due to sickness, back tomorrow!", date: 25, month: 9, year: 2018 }
  ];

  constructor() {}

  public getDay(day: moment.Moment): OpeningHours {
    return this.openingTimes[day.day()];
  }

  public getWeek(): OpeningHours[] {
    return this.openingTimes;
  }

  public isPublicHoliday(date: moment.Moment) {
    return !!this.publicHolidays.filter(holiday => {
      if (date.date() == holiday.date && date.month() == holiday.month - 1) {
        if (holiday.year) {
          return date.year() == holiday.year;
        }

        return true;
      }

      return false;
    }).length;
  }
}

interface TimeDate {
  name?: string;
  date: number;
  month: number;
  year?: number;
}
