import { Router } from "@angular/router";
import { Component, Input } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { environment } from "../../environments/environment";

@Component({
  selector: "no-content",
  templateUrl: "./no-content.component.html"
})
export class NoContentComponent {
  public searchText = "";

  @Input()
  public message: string = "We couldn't find that page.";

  constructor(private router: Router, private titleService: Title) {}

  ngOnInit() {
    this.titleService.setTitle(environment.details.name);
  }

  search(text) {
    if (!text) {
      return;
    }
    this.router.navigate(["/product/search"], { queryParams: { q: text } });
    this.searchText = "";
  }
}
