import { environment } from "../environments/environment.hmr";

export function getImageUrl(image: string, folder: "products" | "products-lg" = "products"): string {
  if (!image) return "";

  const isLocalAsset =
    image.startsWith("categories/") ||
    image.startsWith("headers/") ||
    image.startsWith("schools/") ||
    image.indexOf("/") < 0;
  const isAbsolute =
    image.startsWith("/assets/") ||
    image.startsWith("http:") ||
    image.startsWith("https:") ||
    image.startsWith("data:");

  if (isAbsolute) return image;
  if (isLocalAsset) return `assets/thumbnails/${image}`;

  return `${environment.aws.imageBucket}/${folder}/${encodeURIComponent(image)}`;
}

export function getLargeImageUrl(image: string): string {
  return getImageUrl(image, "products-lg");
}
