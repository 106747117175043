import { Component, Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Observable } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { CategoriesQuery } from "../state/categories/categories.query";
import { UserExtra } from "../state/users/user.model";
import { UserService } from "../user.service";
import { Category } from "./../model/category";
import { OpeningHoursComponent } from "./../opening-hours/opening-hours.component";
import { RoutesService } from "./../routes/routes.service";

@Injectable()
@Component({
  selector: "navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
  viewProviders: [OpeningHoursComponent]
})
export class NavigationComponent implements OnDestroy {
  public appDetails = environment.details;

  public categories$: Observable<Category[]>;

  public searchText = "";

  public user$: Observable<UserExtra>;
  public hideHeader$: Observable<boolean>;

  open = false;

  constructor(
    activeRoute: ActivatedRoute,
    private router: Router,
    private routesService: RoutesService,
    public userService: UserService,
    public categoriesQuery: CategoriesQuery,
  ) {
    this.user$ = userService.$user;

    this.categoriesQuery.syncCollectionUntilDestroyed(this);

    this.categories$ = this.categoriesQuery.topLevelCategories$;

    this.hideHeader$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      tap(() => (this.open = false)),
      map(() => {
        return activeRoute.firstChild.snapshot.data.hideHeader;
      })
    );
  }

  ngOnDestroy(): void {}

  get searchSuggestions(): string[] {
    return this.routesService.getMultipleProductAttributes("brand", "hints", "features", "code");
  }

  search(text) {
    if (!text) {
      return;
    }
    this.router.navigate(["/product/search"], { queryParams: { q: text } });
    this.searchText = "";
  }

  signOut() {
    return this.userService.signOut();
  }
}
