<footer id="footer-area" class="container">
  <div class="footer-links">
    <div class="row">
      <div class="col-md-2 col-sm-6">
        <h5>Categories</h5>
        <ul>
          <li *ngFor="let category of categories$ | async" routerLinkActive="active">
            <a [routerLink]="['/category/', category.path]">{{ category.name }}</a>
          </li>
        </ul>
      </div>
      <div class="col-md-2 col-sm-6">
        <h5>Service</h5>
        <ul>
          <li><a routerLink="/contact-us">Contact Us</a></li>
          <li><a routerLink="/admin/account">Admin</a></li>
          <li><a routerLink="/portal/home">Order Portal</a></li>
        </ul>
      </div>
      <div class="col-md-2 col-sm-6">
        <h5>Follow Us</h5>
        <ul>
          <li>
            <a [href]="appDetails.facebookHref" target="_blank" rel="noopener noreferrer"
              >Facebook</a
            >
          </li>
        </ul>
      </div>
      <div class="col-md-4 col-sm-12 last">
        <h5>Contact Us</h5>
        <ul>
          <li>{{ appDetails.name }}</li>
          <li *ngIf="appDetails.addressHref">
            <a [href]="appDetails.addressHref" target="_blank" rel="noopener noreferrer">{{ appDetails.addressFormatted }}</a>
          </li>
          <li>
            Email: <a href="mailto:{{ appDetails.email }}">{{ appDetails.email }}</a>
          </li>
        </ul>
        <h4 class="lead">
          Tel:
          <span
            ><a href="tel:{{ appDetails.phone }}">{{ appDetails.phoneFormatted }}</a></span
          >
        </h4>
      </div>
    </div>
  </div>
  <div class="copyright">
    <div class="clearfix">
      <p class="pull-left">&nbsp; {{ now | date: "yyyy" }} © {{ appDetails.name }}</p>
      <p class="pull-right">v{{ appVersion }} - {{ appDate | date: "medium" }}</p>
    </div>
  </div>
</footer>
