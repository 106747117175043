import { Injectable } from "@angular/core";
import { Role } from "./role.model";
import { EntityState, ActiveState, StoreConfig } from "@datorama/akita";
import { FirebaseStore } from "../firebase.store";
import { DocumentReference } from "@angular/fire/firestore";

export interface RoleState extends EntityState<Role, string>, ActiveState<string> {}

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "role", deepFreezeFn: _ => _ })
export class RoleStore extends FirebaseStore<RoleState> {
  constructor() {
    super();
  }

  akitaPreAddEntity(entity: Role) {
    return {
      ...entity,
      company: typeof entity.company === "string" ? <any>entity.company : (<DocumentReference>entity.company).id
    };
  }

  akitaPreUpdateEntity(entity: Role) {
    return {
      ...entity,
      company: typeof entity.company === "string" ? <any>entity.company : (<DocumentReference>entity.company).id
    };
  }
}
