import { Injectable } from "@angular/core";
import { WebEvent } from "./webevent.model";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface WebEventsState extends EntityState<WebEvent<any>> {}

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "webevents" })
export class WebEventsStore extends EntityStore<WebEventsState> {
  constructor() {
    super();
  }
}
