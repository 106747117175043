import { Injectable } from "@angular/core";
import { ProductStore, ProductState, toRoleId } from "./product.store";
import { CollectionConfig } from "akita-ng-fire";
import { FirebaseService } from "../firebase.service";
import { Product } from "../../model/product";

@Injectable({ providedIn: "root" })
@CollectionConfig({ path: "products" })
export class ProductService extends FirebaseService<ProductState> {
  constructor(store: ProductStore) {
    super(store);
  }

  preFormat<E extends Product>(entity: Readonly<Partial<Product>>): E {
    if (!entity.roles) {
      return <any>{ ...entity };
    }

    return <any>{
      ...entity,
      roles: <any>entity.roles.map(toRoleId)
    };
  }
}
