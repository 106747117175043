<div class="product-col zoom">
  <a class="featured-image-link" routerLink="/product/{{ product.id || product.code }}">
    <div class="thumb">
      <div class="thumb-image">
        <div class="featured-image" [lazyLoad]="getImage(product.image)"></div>
      </div>
    </div>
    <div class="thumb-info">
      <div class="thumb-title">
        <div class="thumb-name">
          <h2 class="center">{{ product.code }} - {{ product.brand }}</h2>
        </div>
        <div class="thumb-cat" *ngIf="product.brand && product.code">
          <h5 class="center" [title]="product.name">{{ product.name }}</h5>
        </div>
      </div>
    </div>
  </a>
</div>

<ng-template #loading>
  <div class="featured-image">
    <app-loading></app-loading>
  </div>
</ng-template>
