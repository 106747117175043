import { Injectable } from "@angular/core";
import { ActiveState, EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { Category } from "../../model/category";

export interface CategoriesState extends EntityState<Category, string>, ActiveState<string> {}

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "categories" })
export class CategoriesStore extends EntityStore<CategoriesState> {
  constructor() {
    super();
  }

  akitaPreAddEntity(category: Category): Category {
    return CategoriesStore.addFullPath(category);
  }

  akitaPreUpdateEntity(category: Category): Category {
    return CategoriesStore.addFullPath(category);
  }

  static addFullPath(category: Category): Category {
    if (!category) return;

    const cat = {
      ...category,
      fullPath:
        category.fullPath ||
        (category.parentPath
          ? ["/category", category.parentPath, category.path].join("/")
          : "/category/" + category.path)
    };
    return cat;
  }
}
