import { Injectable } from "@angular/core";
import { CollectionConfig } from "akita-ng-fire";
import { FirebaseService } from "../firebase.service";
import { WebEventsState, WebEventsStore } from "./webevents.store";

@Injectable({ providedIn: "root" })
@CollectionConfig({ path: "webevents" })
export class WebEventsService extends FirebaseService<WebEventsState> {
  constructor(store: WebEventsStore) {
    super(store);
  }
}
