import { Injectable } from "@angular/core";
import { CollectionConfig } from "akita-ng-fire";
import { FirebaseService } from "../firebase.service";
import { extractCompanyId, Role } from "./role.model";
import { RoleState, RoleStore } from "./role.store";

@Injectable({ providedIn: "root" })
@CollectionConfig({ path: "roles" })
export class RoleService extends FirebaseService<RoleState> {
  constructor(store: RoleStore) {
    super(store);
  }

  protected preFormat<E extends Role>(role: Readonly<Partial<E>>): E {
    return <any>{
      ...role,
      company: this.db.collection("companies").doc(extractCompanyId(role))
    };
  }
}
