<div class="container">
  <div class="row">
    <div class="col-sm-12">
      <div class="panel-smart text-center">
        <h3>Oops!</h3>
        <h5>{{message}}</h5>
      </div>
      <br />
      <div class="col-xs-12 col-sm-6 col-sm-offset-3">
        <div id="search">
          <div class="input-group">
            <input
              [(ngModel)]="searchText"
              (keyup.enter)="search(searchText)"
              type="text"
              class="form-control input-lg"
              placeholder="Search for it here..."
            />
            <div class="input-group-btn">
              <button class="btn btn-lg" type="button" (click)="search(searchText)">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
</div>
