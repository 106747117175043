import { DocumentReference } from "@angular/fire/firestore";
import { Timestamp } from "@firebase/firestore-types";

export interface RoleBase {
  id: string;
  name: string;
  companyName: string;
  quota: number;
  products?: string[];
  image?: string;

  test?: boolean;
  lastQuotaReset?: Timestamp;
}

export interface Role extends RoleBase {
  company: string | DocumentReference;
}

export interface RoleSimple extends RoleBase {
  company: string;
}

export function toRoleSimple(role: Role): RoleSimple {
  const roleSimple: RoleSimple = {
    ...role,
    // This might be better if it was an id.. but would need to look into the implications of changing it to just use RoleQuery.extractCompanyId
    company: typeof role.company === "string" ? role.company : role.company?.path
  };

  if (!roleSimple.company) {
    delete roleSimple.company;
  }

  return roleSimple;
}

export function extractCompanyId(role: Partial<Role>) {
  return typeof role.company === "string" ? role.company : role.company?.id;
}
