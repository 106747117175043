import { Injectable } from "@angular/core";
import * as Fuse from "fuse.js";
import { flatMap, includes, sampleSize } from "lodash";
import { ProductQuery } from "../state/products/product.query";
import { categories, subcategories } from "./../data/categories";
import { Category } from "./../model/category";
import { Product } from "./../model/product";

@Injectable()
export class RoutesService {
  private searchFields = [
    "code",
    "brand",
    "name",
    "description",
    "category",
    "type",
    "features",
    "hints",
    "fabric",
    "demographic",
    "colours"
  ];

  constructor(private productQuery: ProductQuery) {}

  get products() {
    return this.productQuery.getAll();
  }

  public getCategoryPathsDeprecated(): string[] {
    const categoryPaths = categories.map(c => c.path).filter(c => !subcategories.has(c));

    const subcategoryPaths = subcategories.map((categories, key) => {
      return categories.map(category => key + "/" + category.path);
    });

    return [...categoryPaths, ...flatMap(subcategoryPaths)];
  }

  public getCategory(categoryPaths: string[], categories: Category[]): Category {
    if (!categoryPaths.length) {
      return null;
    }

    const categoryId = categoryPaths[categoryPaths.length - 1];
    return categories.find(
      cat => cat.path === categoryId && cat?.parentPath === this.getParentPaths(categoryPaths)?.join("/")
    );
  }

  public getParentPaths(categoryPaths: string[]): string[] {
    if (categoryPaths.length <= 1) {
      return null;
    }

    return categoryPaths.slice(0, categoryPaths.length - 1);
  }

  public getParent(categoryPaths: string[], categories: Category[]): Category | null {
    if (categoryPaths.length <= 1) {
      return null;
    }

    return this.getCategory(this.getParentPaths(categoryPaths), categories);
  }

  public getProduct(productCode: string): Product {
    let filteredProducts = this.products.filter(product => {
      return product.code === productCode;
    });

    if (!filteredProducts.length) {
      return null;
    }

    return filteredProducts[0];
  }

  public getProductsByCategory(category: string): Product[] {
    return this.productQuery.getAll({
      filterBy: p => p.category === category
    });
  }

  public getRelatedProducts(product: Product): Product[] {
    return this.products.filter(other => {
      return includes(product.relatedStyles, other.code);
    });
  }

  public getRecommended(productCode: string, size: number = 4): Product[] {
    let product = this.getProduct(productCode);

    let categoryProducts = [];
    let relatedStyles = [];
    try {
      categoryProducts = this.getProductsByCategory(product.category);
      relatedStyles = this.getRelatedProducts(product);
    } catch (e) {}

    let filteredProducts = categoryProducts.filter(product => {
      return product.code !== productCode;
    });

    if (!filteredProducts.length) {
      filteredProducts = this.products.filter(product => {
        return product.code !== productCode;
      });
    }

    let recommended = sampleSize(filteredProducts, size - relatedStyles.length);
    return this.uniq(relatedStyles.concat(recommended));
  }

  public getMultipleProductAttributes(attribute: string, ...attributes: string[]) {
    let attributeValues = this.getProductAttributes(attribute);
    for (const attr of attributes) {
      attributeValues = attributeValues.concat(this.getProductAttributes(attr));
    }
    return this.uniq(attributeValues);
  }

  public getProductAttributes(attribute: string): string[] {
    return this.uniq([].concat(...this.products.map(product => product[attribute]))).filter(notNull => notNull);
  }

  public searchForCategories(categories: Category[], text: string): Category[] {
    const categoryFuse = new Fuse(categories, {
      shouldSort: true,
      threshold: 0.4,
      keys: ["name", "subtitle", "image", "path", "hints"]
    });

    return categoryFuse.search<Category>(text);
  }

  searchInProducts(products: Product[], text: string): Product[] {
    let productFuse = new Fuse(products, {
      shouldSort: true,
      threshold: 0.4,
      keys: this.searchFields
    });
    return productFuse.search<Product>(text);
  }

  public uniq(array: any[]): any[] {
    return array.filter((elem, pos, arr) => {
      return arr.indexOf(elem) === pos;
    });
  }
}
