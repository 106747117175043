import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { VERSION } from "../../environments/version";
import { CategoriesQuery } from "../state/categories/categories.query";
import { Category } from "./../model/category";

@Component({
  selector: "footer-bar",
  templateUrl: "./footer-bar.component.html",
  styleUrls: ["./footer-bar.component.scss"]
})
export class FooterBarComponent implements OnInit {
  public appDetails = environment.details;

  public categories$: Observable<Category[]>;

  public appVersion = `${VERSION.version}-${VERSION.hash}`;
  public appDate = VERSION.date;

  public now = new Date();

  constructor(categoriesQuery: CategoriesQuery) {
    this.categories$ = categoriesQuery.topLevelCategories$;
  }

  ngOnInit() {}
}
