import { Injectable } from "@angular/core";
import { StoreConfig } from "@datorama/akita";
import { OrderPortalProduct } from "../../model/product";
import { CollectionState } from "akita-ng-fire";
import { FirebaseStore } from "../firebase.store";
import { Role } from "../roles/role.model";

export interface ProductState extends CollectionState<OrderPortalProduct> {}

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "product", deepFreezeFn: _ => _ })
export class ProductStore extends FirebaseStore<ProductState> {
  constructor() {
    super();
  }

  akitaPreAddEntity(entity: OrderPortalProduct) {
    if (!entity.roles) {
      return entity;
    }

    return {
      ...entity,
      roles: entity.roles.map(toRoleId)
    };
  }

  akitaPreUpdateEntity(entity: OrderPortalProduct) {
    if (!entity.roles) {
      return entity;
    }

    return {
      ...entity,
      roles: entity.roles.map(toRoleId)
    };
  }
}

export function toRoleId(role: string | Role) {
  return typeof role === "string" ? role : role.id;
}
