import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { intersectionObserverPreset, LazyLoadImageModule } from "ng-lazyload-image";
import { CategoryCardComponent } from "../category-card/category-card.component";
import { OpeningHoursProvider } from "../data/opening-hours.provider";
import { NoContentComponent } from "../no-content/no-content.component";
import { OpeningHoursComponent } from "../opening-hours/opening-hours.component";
import { OpeningHoursService } from "../opening-hours/opening-hours.service";
import { FieldSortPipe } from "../order-portal/field-sort.pipe";
import { FireImgComponent } from "../order-portal/fire-img/fire-img.component";
import { ExampleSortPipe } from "../order-portal/product-order/example-sort.pipe";
import { TimestampPipe } from "../order-portal/timestamp.pipe";
import { ProductCardComponent } from "../product-card/product-card.component";
import { ImageModalComponent } from "./image-modal/image-modal.component";
import { LoadingComponent } from "./loading/loading.component";
import { LoadingSpinnerComponent } from "./loading-spinner.component";

const MODULES = [CommonModule, FormsModule, RouterModule, NgbModalModule];

const COMPONENTS = [
  NoContentComponent,
  ProductCardComponent,
  CategoryCardComponent,
  OpeningHoursComponent,
  ImageModalComponent,
  LoadingSpinnerComponent,
  LoadingComponent,
  FireImgComponent,
  FieldSortPipe,
  TimestampPipe,
  ExampleSortPipe
];

const SERVICES = [OpeningHoursProvider, OpeningHoursService];

@NgModule({
  imports: [
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset
    }),
    ...MODULES
  ],
  declarations: [...COMPONENTS],
  providers: [...SERVICES],
  exports: [...COMPONENTS, ...MODULES]
})
export class SharedModule {}
