import { NoContentComponent } from "./../no-content/no-content.component";

import { Routes } from "@angular/router";
import { environment } from "../../environments/environment";

const appName = environment.details.name;

export const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./../home/home.module").then(m => m.HomeModule),
    data: { title: appName }
  },
  {
    path: "contact-us",
    loadChildren: () => import("./../contact-us/contact-us.module").then(m => m.ContactUsModule),
    data: { title: "Contact Us - " + appName }
  },
  {
    path: "category",
    loadChildren: () => import("./../category/category.module").then(m => m.CategoryModule)
  },
  {
    path: "product",
    loadChildren: () => import("./../product/product.module").then(m => m.ProductModule)
  },
  {
    path: "admin",
    loadChildren: () => import("./../admin/admin.module").then(m => m.AdminModule),
    data: { title: "Admin - " + appName, hideHeader: true }
  },
  {
    path: "group-page",
    loadChildren: () => import("../group-pages/group-pages.module").then(m => m.GroupPagesModule),
    data: { title: "Group Order - " + appName, hideHeader: true }
  },
  {
    path: "portal",
    loadChildren: () => import("./../order-portal/order-portal.module").then(m => m.OrderPortalModule),
    data: { title: "Order Portal - " + appName, hideHeader: true }
  },
  {
    path: "**",
    component: NoContentComponent,
    data: { title: appName + " - Not Found" }
  }
];
