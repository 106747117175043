import { Injectable } from "@angular/core";
import { EventsStore, EventsState } from "./events.store";
import { CollectionConfig } from "akita-ng-fire";
import { FirebaseService } from "../firebase.service";

@Injectable({ providedIn: "root" })
@CollectionConfig({ path: "events" })
export class EventsService extends FirebaseService<EventsState> {
  constructor(store: EventsStore) {
    super(store);
  }
}
