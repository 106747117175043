import { demoEnvironment, environmentDefault, PortalEnvironment } from "./environment.default";

const demoMode = true;

export const environment: PortalEnvironment = {
  ...(demoMode ? demoEnvironment : environmentDefault),
  toggles: {
    ...(demoMode ? demoEnvironment : environmentDefault).toggles,
    devTools: true
  },
  envName: "hmr",
  production: false,
  hmr: true,
  useHash: false
};
