<div class="full">
  <navigation></navigation>
  <router-outlet></router-outlet>
  <div class="row" *ngIf="loading">
    <div class="col-xs-12">
      <app-loading></app-loading>
    </div>
  </div>
  <footer-bar></footer-bar>
</div>
