import { Injectable } from "@angular/core";
import { Event } from "./event.model";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface EventsState extends EntityState<Event<any>> {}

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "events" })
export class EventsStore extends EntityStore<EventsState> {
  constructor() {
    super();
  }
}
